import styled from 'styled-components'

const DetailsWrapper = styled.div`
  border-radius: 3px;
  // background: #fff;
  background: rgba(0,0,0,0.2);
  max-width: 600px;
  padding: 10px;
  @media(min-width: 767px){
    box-shadow: 0 0 15px rgba(0,0,0,.6);
    padding: 10px;
  }
  // display: block;
  margin: 0 auto;
  position: relative;
`

export default DetailsWrapper
