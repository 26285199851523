import styled from 'styled-components'

const AddToCartDetailBtn = styled.button`
  &:hover{
    transform: scale(1.01);
    cursor: pointer;
  },

  :active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    animation: moveInBottom .8s ease-out !important;
    animation-fill-mode: backwards !important;
    .addIcon{
      font-size: 30px;
    }
    }

    @keyframes moveInBottom {
      0% {
          // opacity: 0;
          transform: translateY(20px);
      }

      100% {
          // opacity: 1;
          transform: translateY(0px);
      }
    }
`

export default AddToCartDetailBtn
