import styled from 'styled-components'

const Heading = styled.h1`
  background-image: linear-gradient(180deg, #fff, #DF8136);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  filter: drop-shadow(2px 2px #000000);
  font-size: 70px;
  text-align: center;
  margin: 20px;
  font-weight: 400;
`

export default Heading
